<template>
  <div id="app">
    <div class="header">
      <van-nav-bar
        title=""
        left-arrow
        fixed
        placeholder
        :border="false"
        @click-left="onClickLeft"
        right-text="密码登录"
        @click-right="onClickRight"
      />
      <div class="img">
        <img
          src="@/assets/img/logo.png"
          alt=""
          class="img"
          style="width: 93px; height: 93px"
        />
        <span style="font-size: 18px">Win生活</span>
      </div>
    </div>

    <div class="form">
      <van-field
        v-model="form.phone"
        left-icon="iconfont1 icon-phone"
        placeholder="请输入您的手机号"
      />

      <van-field
        v-model="form.code"
        left-icon="iconfont1 icon-possword"
        placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button size="small" type="primary" @click="getcode1">{{
            msg
          }}</van-button>
        </template>
      </van-field>
    </div>

    <div class="button">
      <van-button type="primary" @click="submit()">手机登录</van-button>
    </div>
    <div class="footer">
      <van-checkbox v-model="checked"></van-checkbox>
      <span style="margin-left: 10px">登录即表示您已详细阅读并同意</span>
      <span
        style="color: #ff0000"
        @click="$router.push({ path: '/userAgreement' })"
        >《用户协议》</span
      >
      与
      <span
        style="color: #ff0000"
        @click="$router.push({ path: '/privacyPolicy' })"
        >《隐私协议》</span
      >
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getcode, codeLogin, sendQrCode, scanCode } from "@/api/login";
export default {
  data() {
    return {
      form: {
        phone: null,
        code: null,
        loginCode: '',
        appVersion: 'app'
      },
      checked: false,
      msg: "获取验证码",
    };
  },
  methods: {
    onClickLeft() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },
    onClickRight() {
      this.$router.push({
        path: "/phone",
        query: {
          invite_code: this.$route.query.invite_code,
          userId: this.$route.query.userId
        }
      });
    },
    getcode1() {
      if (this.msg == "获取验证码") {
        if (this.verify(this.form.phone)) {
          getcode({
            phone: this.form.phone,
            type: 1,
          }).then((res) => {
            if (res.data.code === 0) {
              var num = 60;
              const that = this;
              console.log(res);
              var interval = setInterval(function () {
                num -= 1;
                that.msg = num + "秒后重新获取";
                if (num <= 0) {
                  clearInterval(interval);
                  that.msg = "获取验证码";
                }
              }, 1000);
            } else {
              Toast(res.data.message)
            }
          });
        } else {
          Toast.fail("请输入正确的手机号");
        }
      }
    },
    verify(val) {
      return /^1\d{10}$/.test(val);
    },
    submit() {
      if (this.checked) {
        if (!this.verify(this.form.phone)) {
          Toast.fail("请输入正确的手机号");
        } else if (!this.form.code) {
          Toast.fail("请输入验证码");
        } else {
          // 验证通过
          this.form.loginCode =  this.$route.query.invite_code
          var form1 = JSON.stringify(this.form);
          codeLogin(form1).then((res) => {
            if (res.data.code == 0) {
              sendQrCode({
                qrCode: this.$route.query.invite_code,
                // qrCode: 'onrgY4KR',
                userId: res.data.data.id
              }).catch(() => {
                Toast('绑定失败，请重新扫码绑定')
              })

              scanCode({
                userId: res.data.data.id,
                parentId: this.$route.query.userId,
                type: this.$route.query.type
              })

              this.openApp(
                "login?type=" +
                  this.$route.query.type +
                  "&phone=" +
                  this.form.phone +
                  "&password=" +
                  this.form.password +
                  "&userId=" +
                  this.$route.query.userId,
                true
              )
            } else {
              if (res.data.message) {
                Toast.fail(res.data.message)
              } else {
                Toast.fail(res.data.msg)
              }
            }
          });
        }
      } else {
        Toast.fail("请详细阅读并同意《用户协议》 与 《隐私协议》");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
}

.img {
  width: 93px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 11;
  margin-top: 50px;
}

.contentInfo {
  display: flex;
  justify-content: center;
  margin: 40px 0;

  span {
    margin: 0 2px;
  }
}

.button {
  display: flex;
  justify-content: center;
}

.button .van-button--primary {
  width: 343px;
  height: 43px;
  background: #f52556;
  opacity: 1;
  border-radius: 22px;
  border: none;
  margin-top: 50px;
}

.van-nav-bar {
  background-color: transparent;
}

/deep/ .van-nav-bar__text {
  color: black;
  font-size: 17px;
}

/deep/.van-nav-bar__left .van-icon-arrow-left {
  color: black;
}

.van-cell {
  background-color: #f8f8f8;
  border-radius: 18px;
  width: 96%;
  margin: 2%;
}

.form {
  display: flex;
  width: 96%;
  flex-direction: column;
  justify-content: end;
  margin: 20px 2%;
  margin-bottom: 0;
}

.form .van-button--primary {
  background: #f52556;
  border-radius: 22px;
  border: none;
  height: 25px;
}

.footer {
  margin: 2%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 25px;
}
/deep/ .van-field__left-icon .van-icon,
.van-field__right-icon .van-icon {
  font-size: 20px;
  font-weight: bold;
  color: #999999;
}
/deep/ .van-field__control {
  font-size: 16px;
  margin-left: 8px;
}
</style>
